import React, { Component } from "react";
import { Page } from "pages/page";
import policy from "pages/policy";
import { faHome } from "@fortawesome/free-solid-svg-icons";

class Home extends Component {
	public render (): JSX.Element {
		return <div>
			<b>Welcome to the fangame archive!</b>
			<br /><br />
			This place aims to archive all the lost fangames of <b>I wanna be the guy</b>.<br />
			If you are looking for a particular fangame, the first place to check is <a href="https://delicious-fruit.com/">delicious-fruit</a>.
			<br /><br />
			For more informations about our policy, make sure to check <a href={ `/${ policy.getURL() }` }>this page</a>.
			<br /><br />
			Enjoy your stay!
		</div>;
	}
}

const page = new Page({
	name: "Home",
	content: <Home />,
	default: true,
	icon: faHome,
});

export default page;
