export enum HTTPCode {
    Ok = 200,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Internal = 500
}

export enum Role {
    Admin = 0,
    Moderator = 1,
    User = 2,
    Everyone = 3
}

export enum GameStatus {
    Confirmed = 0,
    Unconfirmed = 1,
    Pending = 2,
    Hidden = 3,
    Removed = 4
}

export const PENDING_DAYS = 3;

type Field = "id" | "name" | "url" | "delfruit";

interface Change {
    field: Field;
    oldValue: string;
    newValue: string;
}

export interface Update {
    id: number;
    changes: Change[];
}
