import React, { Component } from "react";
import { Page } from "pages/page";
import Button from "ui/button";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

class Discord extends Component {
	public render (): JSX.Element {
		return <div>
			We have a discord server to help us archiving everything.
			<br />
			If there is a game you can't find here, you can always come and ask us directly!
			<br />
			All you need is a discord account, and... we'll happily meet you:
			<br />
			<Button
				name="Join us"
				href="https://discord.com/invite/HSeycPx"
			/>
			<br />
			(pssst: we even have a bot for finding fangames quicker)
		</div>;
	}
}

const page = new Page({
	name: "Discord",
	content: <Discord />,
	icon: faDiscord,
});

export default page;
