import React, { Component } from "react";

import styles from "./index.module.scss";

export default class Loading extends Component {
	public render (): JSX.Element {
		return <div className={ styles.container }>
			<div>
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>;
	}
}