import React, { Component } from "react";
import { Page } from "pages/page";
import Connect from "./connect";
import Space from "./space";
import Api from "api";
import { Role } from "../../common";
import Loading from "ui/loading";
import { faUser } from "@fortawesome/free-solid-svg-icons";

type Props = {};

interface State {
	isConnected: boolean;
	isLoading: boolean;
}

class Account extends Component<Props, State> {
	public constructor (props: Props) {
		super(props);
		this.state = {
			isConnected: false,
			isLoading: true,
		};
	}
	public componentDidMount (): void {
		(async () => {
			const role = await Api.getRole();
			const isConnected = role !== Role.Everyone;
			this.setState({
				isConnected,
				isLoading: false,
			});
		})()
		.catch(console.error);
	}
	public render (): JSX.Element {
		if (this.state.isLoading)
			return <Loading />;
		if (this.state.isConnected)
			return <Space />;
		return <Connect />;
	}
}

const page = new Page({
	name: "Account",
	content: <Account />,
	icon: faUser,
});

export default page;
