import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Button from "ui/button";
import IconButton from "ui/icon-button";
import Input from "ui/input";

import styles from "./index.module.scss";

interface Props {
	initValue: string;
}

const search = async function (value: string): Promise<void> {
	const url = new URL(window.location.href);
	url.searchParams.set("q", encodeURIComponent(value));
	window.location.assign(url.href);
}

export const SearchInput = function ({ initValue }: Props) {
	const [value, setValue] = useState(initValue);
	const [show, setShow] = useState(false);
	return <div className={ styles.container }>
		<div className={ styles.input }>
			<Input
				value={ value }
				onChange={ value => setValue(value) }
				placeholder="Search..."
				onEnter={ () => search(value).catch(console.error) }
			/>
			<Button
				onClick={ () => search(value).catch(console.error) }
				name="Search"
			/>
			<Button
				onClick={ () => search("*").catch(console.error) }
				name="Full list"
			/>
			<div className={ styles.question }>
				<IconButton
					icon={ faQuestionCircle }
					color={ show ? "#007bff" : "gray" }
					onClick={ () => setShow(!show) }
				/>
			</div>
		</div>
		<div
			className={ styles.help }
			custom-hidden={ (!show).toString() }
		>
			<div>To get the full list, type <span>*</span></div>
			<div>You can insert double quotes around a phrase to force exact matches.</div>
			<div>To improve the search experience, the following words are ignored: {
				["i", "wanna", "be", "the"]
				.map((word, i) => <span key={ i }>{ i === 0 ? "" : "," } { word }</span>)
			}</div>
		</div>
	</div>;
}