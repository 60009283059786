import React, { Component } from "react";
import { Page } from "pages/page";
import Api, { List as TypeList } from "api";
import Loading from "ui/loading";
import { IconInfo } from "ui/icon-info";
import { SearchInput } from "./input";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import styles from "./index.module.scss";

type Props = {};

interface State {
	list: TypeList;
	isLoading: boolean;
	search: string;
}

class Search extends Component<Props, State> {
	private readonly search: string;
	public constructor (props: Props) {
		super(props);
		this.state = {
			list: [],
			isLoading: true,
			search: "",
		};
		const url = new URL(window.location.href);
		const q = url.searchParams.get("q") ?? "";
		this.search = decodeURIComponent(q);
	}
	public componentDidMount (): void {
		(async () => {
			const list = await Api.search(this.search);
			this.setState({ list, isLoading: false });
		})()
		.catch(console.error);
	}
	public renderResultsInfo (n: number): JSX.Element {
		if (this.state.isLoading)
			return <></>;
		return <div className={ styles.results }>
			{ n } result{ n === 1 ? "" : "s" }
		</div>;
	}
	public render (): JSX.Element {
		return <div>
			<SearchInput initValue={ this.search } />
			<div>
				{ this.state.isLoading ? <Loading /> : <></> }
				{ this.renderResultsInfo(this.state.list.length) }
				{ this.state.list.map(({ id, name, status }, i) => <div key={ i }>
					{ <IconInfo status={ status } /> } <a href={ `/game/${ id }` }>{ name }</a>
				</div>) }
			</div>
		</div>;
	}
}

const page = new Page({
	name: "Search",
	content: <Search />,
	icon: faSearch,
});

export default page;
