export const saveToken = function (res: any): void {
    if (!res)
        return;
    const jwtToken = res.jwtToken;
    if (!jwtToken)
        return;
    if (typeof jwtToken !== "string")
        return;
    localStorage.setItem("jwtToken", jwtToken);
}

export const getToken = function (): string | undefined {
    return localStorage.getItem("jwtToken") ?? undefined;
}

export const destroyToken = function (): void {
    localStorage.removeItem("jwtToken");
}
