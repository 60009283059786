import React, { Component } from "react";
import { Page } from "pages/page";
import Button from "ui/button";
import Input from "ui/input";
import Api from "api";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

type Props = object;

interface State {
	name: string;
	url: string;
}

class Submit extends Component<Props, State> {
	public constructor (props: Props) {
		super(props);
		this.state = {
			name: "",
			url: "",
		};
	}
	public async submit (): Promise<void> {
		const id = await Api.submitGame(this.state.name, this.state.url);
		const url = `/game/${ id }`;
		window.location.assign(url);
	}
	public render (): JSX.Element {
		return <div style={{ textAlign: "center" }}>
			<b>Do not submit leaked versions of fangames which are in active development, nor cracked paid fangames!</b>
			<br /><br />
			Game name
			<br />
			<Input
				value={ this.state.name }
				onChange={ name => this.setState({ name }) }
				placeholder="I wanna be the..."
				onEnter={ () => { this.submit().catch(console.error) } }
			/>
			<br />
			Download link
			<br />
			<Input
				value={ this.state.url }
				onChange={ url => this.setState({ url }) }
				placeholder="Download url"
				onEnter={ () => { this.submit().catch(console.error) } }
			/>
			<br />
			<Button
				name="Submit"
				onClick={() => { this.submit().catch(console.error) }}
			/>
		</div>;
	}
}

const page = new Page({
	name: "Submit",
	content: <Submit />,
	icon: faPaperPlane,
});

export default page;
