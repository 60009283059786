import React, { Component } from "react";
import Input from "ui/input";
import Button, { ButtonType } from "ui/button";
import Api from "api";
import { ErrorMessage, ErrorType } from "ui/error";

import styles from "./index.module.scss";

enum Step {
	Choice,
	LogIn,
	SignIn,
}

type Props = {};

interface State {
	username: string;
	password: string;
	confirm: string;
	error: ErrorType;
	step: Step;
}

export default class Connect extends Component<Props, State> {
	public constructor (props: Props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			confirm: "",
			error: undefined,
			step: Step.Choice,
		};
	}
	public logIn (): void {
		(async () => {
			await Api.logIn(this.state.username, this.state.password);
			window.location.reload();
		})()
		.catch(error => this.setState({ error }));
	}
	public signIn (): void {
		(async () => {
			await Api.signIn(this.state.username, this.state.password, this.state.confirm);
			window.location.reload();
		})()
		.catch(error => this.setState({ error }));
	}
	public renderLogIn (): JSX.Element {
		return <div>
			<div>Username</div>
			<Input
				value={ this.state.username }
				onChange={ username => this.setState({ username }) }
				onEnter={ () => this.logIn() }
			/>
			<div>Password</div>
			<Input
				value={ this.state.password }
				onChange={ password => this.setState({ password }) }
				password
				onEnter={ () => this.logIn() }
			/>
			<Button
				name="Back"
				onClick={ () => this.setState({ step: Step.Choice }) }
				type={ ButtonType.Danger }
			/>
			<Button
				name="Log in"
				onClick={ () => this.logIn() }
			/>
		</div>;
	}
	public renderSignIn (): JSX.Element {
		return <div>
			<div>Username</div>
			<Input
				value={ this.state.username }
				onChange={ username => this.setState({ username }) }
				onEnter={ () => this.signIn() }
			/>
			<div>Password</div>
			<Input
				value={ this.state.password }
				onChange={ password => this.setState({ password }) }
				password
				onEnter={ () => this.signIn() }
			/>
			<div>Confirm password</div>
			<Input
				value={ this.state.confirm }
				onChange={ confirm => this.setState({ confirm }) }
				password
				onEnter={ () => this.signIn() }
			/>
			<Button
				name="Back"
				onClick={ () => this.setState({ step: Step.Choice }) }
				type={ ButtonType.Danger }
			/>
			<Button
				name="Sign up"
				onClick={ () => this.signIn() }
				type={ ButtonType.Success }
			/>
		</div>;
	}
	public renderAccordingToStep (): JSX.Element {
		if (this.state.step === Step.LogIn)
			return this.renderLogIn();
		if (this.state.step === Step.SignIn)
			return this.renderSignIn();
		return <>
			<Button
				name="Log in"
				onClick={ () => this.setState({ step: Step.LogIn }) }
			/>
			<Button
				name="Sign up"
				onClick={ () => this.setState({ step: Step.SignIn }) }
				type={ ButtonType.Success }
			/>
		</>;
	}
	public render (): JSX.Element {
		return <div>
			<ErrorMessage err={ this.state.error } />
			<div className={ styles.container }>
				{ this.renderAccordingToStep() }
			</div>
		</div>;
	}
}
