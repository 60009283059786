import { API_SERVER } from "../config";
import { GameStatus, HTTPCode, Role, Update } from "../common";
import { destroyToken, getToken, saveToken } from "./jwt";

type Mods = Array<{
	name: string;
}>;

export type List = Array<{
	id: string;
	name: string;
	status: GameStatus;
}>;

export interface Comment {
	id: number;
	author: {
		name: string;
		role: Role;
	};
	edited: boolean;
	date: string;
	content: string;
	own: boolean;
}

export interface Comment2 {
	author: {
		name: string;
		role: Role;
	};
	edited: boolean;
	date: string;
	content: string;
	game: {
		name: string;
		id: string;
		status: GameStatus;
	};
}

export interface Game {
	name: string;
	url?: string;
	date: string;
	status: GameStatus;
	delfruitID?: string;
}

enum Method {
	Get = "get",
	Post = "post",
	Put = "put",
	Patch = "patch",
	Delete = "delete",
}

interface AjaxResponse {
	status: number;
	error?: Error;
	data: any;
}

export default class Api {
	private static async ajax (url: string, method: Method, dataParams?: any): Promise<AjaxResponse> {
		try {
			const jwtToken = getToken();
			const response = await fetch(url, {
				mode: "cors",
				credentials: "include",
				method,
				headers: {
					"Content-Type": "application/json",
					"Authorization": jwtToken === undefined ? "" : `Bearer ${ jwtToken }`,
				},
				body: dataParams === undefined ? undefined : JSON.stringify({ data: dataParams }),
			});
			const { status } = response;
			const data = await response.json();
			return {
				status,
				data: status === HTTPCode.Ok ? data : {},
				error: status === HTTPCode.Ok ? undefined : new Error(data),
			};
		} catch (_) {
			return { status: HTTPCode.Internal, data: {}, error: new Error("Communication with the server failed!") };
		}
	}
	private static async call (api: string, method: Method = Method.Get, dataParams?: any): Promise<any> {
		const { data, status, error } = await Api.ajax(`${ API_SERVER }/${ api }`, method, dataParams);
		if (status !== HTTPCode.Ok)
			throw error;
		return data;
	}
	public static async getMods (): Promise<Mods> {
		return await this.call("api/mods");
	}
	public static async search (search: string): Promise<List> {
		return await this.call("api/game/search", Method.Post, { search });
	}
	public static async submitGame (name: string, url: string): Promise<string> {
		if (name.length === 0)
			throw new Error("Name is empty");
		if (url.length === 0)
			throw new Error("URL is empty");
		return await this.call("api/game/submit", Method.Post, { name, url });
	}
	public static async getGame (id: string): Promise<Game> {
		return await this.call(`api/game/${ id }`);
	}
	public static async toggleGameVisibility (id: string): Promise<void> {
		await this.call(`api/game/${ id }/toggle-visibility`, Method.Post);
	}
	public static async reliveGame (id: string): Promise<void> {
		await this.call(`api/game/${ id }/relive`, Method.Post);
	}
	public static async deleteGame (id: string): Promise<void> {
		await this.call(`api/game/${ id }/delete`, Method.Post);
	}
	public static async removeGame (id: string): Promise<void> {
		await this.call(`api/game/${ id }/remove`, Method.Post);
	}
	public static async confirmGame (id: string): Promise<void> {
		await this.call(`api/game/${ id }/confirm`, Method.Post);
	}
	public static async unconfirmGame (id: string): Promise<void> {
		await this.call(`api/game/${ id }/unconfirm`, Method.Post);
	}
	public static async setDelfruit (id: string, delfruitID: string): Promise<void> {
		await this.call(`api/game/${ id }/delfruit`, Method.Post, { delfruitID });
	}
	public static async renameGame (id: string, newName: string): Promise<void> {
		if (newName.length === 0)
			throw new Error("Cannot rename to an empty name");
		await this.call(`api/game/${ id }/rename`, Method.Post, { newName });
	}
	public static async updateGameURL (id: string, newURL: string): Promise<void> {
		if (newURL.length === 0)
			throw new Error("Cannot update url to an empty url");
		await this.call(`api/game/${ id }/updateURL`, Method.Post, { newURL });
	}
	public static async postComment (game: string, comment: string): Promise<void> {
		await this.call("api/comment", Method.Post, { game, comment });
	}
	public static async editComment (id: number, content: string): Promise<void> {
		await this.call("api/comment/edit", Method.Post, { id, content });
	}
	public static async deleteComment (id: number): Promise<void> {
		await this.call("api/comment", Method.Delete, { id });
	}
	public static async getComments (game: string): Promise<Array<Comment>> {
		return await this.call("api/comment/list", Method.Post, { game });
	}
	public static async getAllComments (): Promise<Array<Comment2>> {
		return await this.call("api/comment/all");
	}
	public static async logIn (username: string, password: string): Promise<void> {
		const res = await this.call("auth/login", Method.Post, {
			username,
			password,
		});
		saveToken(res);
	}
	public static async signIn (username: string, password: string, confirm: string): Promise<void> {
		if (password !== confirm)
			throw new Error("Passwords do not match");
		const res = await this.call("auth/signin", Method.Post, {
			username,
			password,
		});
		saveToken(res);
	}
	public static async logOut (): Promise<void> {
		destroyToken();
	}
	public static async getRole (): Promise<Role> {
		return await this.call("auth/role");
	}
	public static async getName (): Promise<string> {
		return await this.call("auth/name");
	}
	public static async changeName (password: string, newName: string): Promise<string> {
		return await this.call("auth/change-name", Method.Post, {
			password,
			newName,
		});
	}
	public static async changePassword (password: string, newPassword: string, newPasswordConfirm: string): Promise<string> {
		if (newPassword !== newPasswordConfirm)
			throw new Error("Passwords do not match");
		return await this.call("auth/change-password", Method.Post, {
			password,
			newPassword,
		});
	}
	public static async getDatabaseBackup (): Promise<object> {
		return await this.call("api/backup");
	}
	public static async saveToSpreadsheet (): Promise<void> {
		await this.call(`api/spreadsheet/save`, Method.Post);
	}
	public static async loadUpdatesFromSpreadsheet (): Promise<{
		updates: Update[],
		summary: string,
	}> {
		return await this.call(`api/spreadsheet/load-updates`);
	}
	public static async applyUpdatesFromSpreadsheet (updates: Update[]): Promise<void> {
		await this.call(`api/spreadsheet/apply-updates`, Method.Post, {
			updates,
		});
	}
}
