import React from "react";
import { Page } from "pages/page";
import { PENDING_DAYS } from "../../common";
import discord from "pages/discord";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Policy = function () {
	return <div>
		This website aims to collect and archive all the lost fangames of <b>I wanna be the guy</b>.
		{/* <br /> */}
		{/* Sadly, some makers may want their games to be private for a wide range of various reasons. We do want to respect these decisions and so are not willing to share such games. */}
		<br />
		<div>
			<h4>Submitting a game</h4>
			If you own a hard to find fangame, submitting it to this website is highly appreciated.
			<br />
			However, please consider a few things before sharing it:
			<ul>
				{/* <li>Do not share a game if their creator clearly stated their disapproval</li> */}
				<li>Do not share leaked versions of unfinished games that are still in development</li>
				<li>Do not share cracked paid games</li>
			</ul>
			{/* The only scenario where we would allow sharing a game despite the disapproval of the creator is if the creator were willing to share their game only with people of a specific color, race, religion, gender or such a discriminatory criterion. */}
			<br />
			{/* <br /> */}
			These rules may not cover every case, so they are likely to evolve.
			<br />
			Also we are open to criticism, so if you think the rules are too weak/strong, feel free to debate <b>peacefully</b> in our <a href={ `/${ discord.getURL() }` }>Discord server</a>.
			<br /><br />
			In order to prevent people from sharing forbidden games before a moderator can notice them, the submissions will be in a pending state for <b>{ PENDING_DAYS } days</b>. Only then the download link will be available.
			<br />
			We hope this can help people reporting forbidden games before everyone downloads it.
		</div>
		<div>
			<h4>Comments</h4>
			When posting a comment, you have to follow some obvious rules:
			<ul>
				<li>Do not attack or harass anyone</li>
				<li>Do not discriminate people (no racism, sexism, etc)</li>
				<li>Avoid political or religious topics</li>
				<li>...just use common sense and everything should be alright</li>
			</ul>
		</div>
		<div>
			<h4>Account and bans</h4>
			First and foremost, the rules about comments also apply to your profile's username.
			<br />
			Moderators can ban your account. If they do, do not create a new account or else both of your accounts will be permanently banned. Instead you can message a moderator on <a href={ `/${ discord.getURL() }` }>Discord</a> for explanations.
		</div>
	</div>;
}

const page = new Page({
	name: "Policy",
	content: <Policy />,
	icon: faInfoCircle,
});

export default page;
