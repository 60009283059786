import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./app";

import "./index.scss";

const root = document.getElementById("root");
if (!root)
  throw new Error("No root element found");
createRoot(root).render(<App />);
