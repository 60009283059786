import React, { Component } from "react";
import { Page } from "pages/page";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import Loading from "ui/loading";
import Api, { Comment2 } from "api";
import { getRoleColor } from "comments/utils";
import game from "pages/game";
import { IconInfo } from "ui/icon-info";

import styles from "./index.module.scss";

type Props = {}

interface State {
	loading: boolean;
	comments: Array<Comment2>;
}

class Comments extends Component<Props, State> {
	public constructor (props: Props) {
		super(props);
		this.state = {
			loading: true,
			comments: [],
		};
	}
	public componentDidMount () {
		(async () => {
			const comments = await Api.getAllComments();
			this.setState({ comments, loading: false });
		})()
		.catch(console.error);
	}
	public render (): JSX.Element {
		if (this.state.loading)
			return <Loading />;
		return <div className={ styles.container }>
			{
				this.state.comments
				.map((comment, i) => <div key={ i }>
					<div className={ styles.game }>
						<IconInfo status={ comment.game.status }/>
						<a href={ `/${ game.getURL() }/${ comment.game.id }` }>
							{ comment.game.name }
						</a>
					</div>
					<span
						className={ styles.author }
						style={{
							color: getRoleColor(comment.author.role),
						}}
					>{ comment.author.name }</span>
					<span className={ styles.date }>{ new Date(comment.date).toLocaleString() }</span>
					<span className={ styles.edited }>{ comment.edited ? "(edited)" : "" }</span>
					<p
						className={ styles.content }
						style={{
							whiteSpace: "pre-line",
						}}
					>{ comment.content }</p>
				</div>)
			}
		</div>;
	}
}

const page = new Page({
	name: "Comments",
	content: <Comments />,
	icon: faComments,
});

export default page;
