import React, { Component } from "react";
import { Page } from "pages/page";
import { setTitle } from "title";
import Api, { Game as GameType } from "api";
import Button, { ButtonType } from "ui/button";
import Comments from "comments";
import { ErrorMessage, ErrorType } from "ui/error";
import Loading from "ui/loading";
import { IconInfo } from "ui/icon-info";
import { GameStatus, PENDING_DAYS, Role } from "../../common";
import humanizeDuration from "humanize-duration";
import Input from "ui/input";

import styles from "./index.module.scss";

type Props = {}

interface State {
	game: GameType | undefined;
	notFound: boolean;
	error: ErrorType;
	isLoading: boolean;
	isModerator: boolean;
	isAdmin: boolean;
	newName: string;
	newURL: string;
	delfruitID: string;
}

class Game extends Component<Props, State> {
	public id: string;
	public constructor (props: Props) {
		super(props);
		this.state = {
			game: undefined,
			notFound: false,
			error: undefined,
			isLoading: true,
			isModerator: false,
			isAdmin: false,
			delfruitID: "",
			newName: "",
			newURL: "",
		};
		const pathNames = window
			.location
			.pathname
			.split("/")
			.filter(a => a.length > 0);
		this.id = pathNames.length > 1 ? pathNames[1] : "";
	}
	public componentDidMount (): void {
		(async () => {
			const [game, role] = await Promise.all([
				Api.getGame(this.id),
				Api.getRole(),
			]);
			this.setState({
				game,
				isLoading: false,
				isModerator: role === Role.Moderator || role === Role.Admin,
				isAdmin: role === Role.Admin,
				newName: game.name,
				newURL: game.url ?? "",
				delfruitID: game.delfruitID ?? "",
			});
			setTitle(game.name);
		})()
		.catch(error => this.setState({ error, isLoading: false }));
	}
	public getRemainingTime (date: string): string {
		const postedAt = new Date(date);
		const postedAt_ms = postedAt.valueOf();
		const pending_ms = PENDING_DAYS * 24 * 60 * 60 * 1000;
		const releaseAt_ms = postedAt_ms + pending_ms;
		const now_ms = Date.now();
		const remaining_ms = releaseAt_ms - now_ms;
		return humanizeDuration(remaining_ms, {
			units: ["d", "h", "m"],
			round: true,
		});
	}
	public getStatusMessage (game: GameType): string {
		switch (game.status) {
			case GameStatus.Confirmed:
				return "This game is available and confirmed";
			case GameStatus.Unconfirmed:
				return "This game is available, but no moderator has confirmed it yet.";
			case GameStatus.Pending:
				return `This game will be available in ${ this.getRemainingTime(game.date) }.`;
			case GameStatus.Hidden:
				return "This game is hidden";
			case GameStatus.Removed:
				return "This game has been removed";
		}
	}
	public async toggleVisibility (): Promise<void> {
		await Api.toggleGameVisibility(this.id);
		window.location.reload();
	}
	public async confirm (): Promise<void> {
		await Api.confirmGame(this.id);
		window.location.reload();
	}
	public async unconfirm (): Promise<void> {
		await Api.unconfirmGame(this.id);
		window.location.reload();
	}
	public async remove (): Promise<void> {
		await Api.removeGame(this.id);
		window.location.reload();
	}
	public async relive (): Promise<void> {
		await Api.reliveGame(this.id);
		window.location.reload();
	}
	public async delete (): Promise<void> {
		await Api.deleteGame(this.id);
		window.location.reload();
	}
	public async rename (): Promise<void> {
		await Api.renameGame(this.id, this.state.newName);
		window.location.reload();
	}
	public async updateURL (): Promise<void> {
		await Api.updateGameURL(this.id, this.state.newURL);
		window.location.reload();
	}
	public async setDelfruitID (): Promise<void> {
		await Api.setDelfruit(this.id, this.state.delfruitID);
		window.location.reload();
	}
	public displayModActions (game: GameType): JSX.Element {
		if (!this.state.isModerator)
			return <></>;
		return <>
			Moderator actions
			<div>
				<Input
					value={ this.state.newName }
					onChange={ newName => this.setState({ newName }) }
					placeholder="Game name"
					onEnter={ () => this.rename().catch(console.error) }
				/>
				<Button
					onClick={ () => this.rename().catch(console.error) }
					name="Rename"
				/>
			</div>
			<div>
				<Input
					value={ this.state.newURL }
					onChange={ newURL => this.setState({ newURL }) }
					placeholder="Game URL"
					onEnter={ () => this.rename().catch(console.error) }
				/>
				<Button
					onClick={ () => this.updateURL().catch(console.error) }
					name="Update game URL"
				/>
			</div>
			<div>
				<Input
					placeholder="Delfruit ID"
					value={ this.state.delfruitID }
					onChange={ delfruitID => this.setState({ delfruitID }) }
					onEnter={ () => this.setDelfruitID().catch(console.error) }
				/>
				<Button
					name="Set delfruit ID"
					onClick={ () => this.setDelfruitID().catch(console.error) }
				/>
			</div>
			<div>
				{
					game.status === GameStatus.Unconfirmed ?
					<Button
						onClick={ () => this.confirm().catch(console.error) }
						name="Confirm game"
						type={ ButtonType.Success }
					/> : <></>
				}
				{
					game.status === GameStatus.Confirmed ?
					<Button
						onClick={ () => this.unconfirm().catch(console.error) }
						name="Unconfirm game"
						type={ ButtonType.Warning }
					/> : <></>
				}
				<Button
					onClick={ () => this.toggleVisibility().catch(console.error) }
					name="Toggle visibility"
					type={ ButtonType.Warning }
				/>
				<Button
					onClick={ () => this.remove().catch(console.error) }
					name="Remove"
					type={ ButtonType.Danger }
				/>
			</div>
		</>;
	}
	public displayAdminActions (game: GameType): JSX.Element {
		if (!this.state.isAdmin)
			return <></>;
		return <>
			Admin actions
			<div>
				{
					game.status === GameStatus.Removed ?
					<Button
						onClick={ () => this.relive().catch(console.error) }
						name="Relive"
						type={ ButtonType.Success }
					/> : <></>
				}
				<Button
					onClick={ () => this.delete().catch(console.error) }
					name="Delete"
					type={ ButtonType.Danger }
				/>
			</div>
		</>;
	}
	public getDelfruitURL (id: string): string {
		return `https://delicious-fruit.com/ratings/game_details.php?id=${ id }`;
	}
	public render (): JSX.Element {
		if (this.state.isLoading)
			return <Loading />;
		if (this.state.error)
			return <ErrorMessage err={ this.state.error } />;
		const game = this.state.game!;
		return <div className={ styles.container }>
			<div>
				<h2>{ game.name }</h2>
				<div className={ styles.date }>{ new Date(game.date).toLocaleString() }</div>
				<div>
					<IconInfo status={ game.status } />
					{ this.getStatusMessage(game) }
				</div>
				<Button
					href={ game.url }
					name="Download"
					disabled={ game.url === undefined }
					type={ ButtonType.Success }
				/>
				{ game.delfruitID === undefined ? <></> : <Button
					href={ this.getDelfruitURL(game.delfruitID) }
					name="Delicious fruit"
				/> }
				{ this.displayModActions(game) }
				{ this.displayAdminActions(game) }
			</div>
			<Comments game={ this.id } />
		</div>;
	}
}

const page = new Page({
	name: "Game",
	content: <Game />,
	menu: false,
	title: false,
});

export default page;
