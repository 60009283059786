import React, { Component } from "react";
import { GameStatus } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
	faCheck,
	faClock,
	faExclamationTriangle,
	faTimes,
	faUserSecret,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./index.module.scss";

interface Props {
	status: GameStatus;
}

export class IconInfo extends Component<Props> {
	public renderIcon (icon: IconProp, color: string, title: string): JSX.Element {
		return <div
			className={ styles.container }
			title={ title }
		>
			<FontAwesomeIcon
				icon={ icon }
				style={{ color }}
			/>
		</div>
	}
	public render (): JSX.Element {
		switch (this.props.status) {
			case GameStatus.Confirmed:
				return this.renderIcon(faCheck, "green", "Confirmed");
			case GameStatus.Unconfirmed:
				return this.renderIcon(faExclamationTriangle, "orange", "Unconfirmed");
			case GameStatus.Pending:
				return this.renderIcon(faClock, "#98c6ff", "Pending");
			case GameStatus.Hidden:
				return this.renderIcon(faUserSecret, "gray", "Hidden");
			case GameStatus.Removed:
				return this.renderIcon(faTimes, "red", "Removed");
		}
		throw new Error("Invalid status");
	}
}
