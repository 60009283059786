import { Role } from "../common";

export const getRoleColor = function (role: Role): string {
	switch (role) {
		case Role.Admin:
			return "green";
		case Role.Moderator:
			return "purple";
		default:
			return "black";
	}
}
