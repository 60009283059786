import Api from "api";
import { Role } from "../../../common";
import React, { Component } from "react";
import Button, { ButtonType } from "ui/button";
import { ErrorMessage, ErrorType } from "ui/error";
import Input from "ui/input";
import Loading from "ui/loading";

import styles from "./index.module.scss";

type Props = {};

interface State {
	isLoading: boolean;
	name: string;
	newName: string;
	password: {
		new: string;
		confirm: string;
		current: string;
	}
	error: ErrorType;
	isAdmin: boolean;
	isModerator: boolean;
	downloadingBackup: boolean;
	spreadsheetLoading: boolean;
}

export default class Space extends Component<Props, State> {
	public constructor (props: Props) {
		super(props);
		this.state = {
			isLoading: true,
			name: "",
			newName: "",
			password: {
				new: "",
				confirm: "",
				current: "",
			},
			error: undefined,
			isAdmin: false,
			isModerator: false,
			downloadingBackup: false,
			spreadsheetLoading: false,
		};
	}
	public componentDidMount (): void {
		(async () => {
			const [name, role] = await Promise.all([
				Api.getName(),
				Api.getRole(),
			]);
			const isAdmin = role === Role.Admin;
			const isModerator = role === Role.Moderator || isAdmin;
			this.setState({ name, isLoading: false, isAdmin, isModerator });
		})()
		.catch(console.error);
	}
	public save (): void {
		(async () => {
			if (this.state.newName.length > 0) {
				await Api.changeName(
					this.state.password.current,
					this.state.newName,
				);
			}
			if (this.state.password.new.length > 0 || this.state.password.confirm.length > 0) {
				await Api.changePassword(
					this.state.password.current,
					this.state.password.new,
					this.state.password.confirm,
				);
			}
			window.location.reload();
		})()
		.catch(error => this.setState({ error }));
	}
	public logOut (): void {
		(async () => {
			await Api.logOut();
			window.location.reload();
		})()
		.catch(error => this.setState({ error }));
	}
	public downloadDatabaseBackup (): void {
		this.setState({ downloadingBackup: true }, () => {
			(async () => {
				const backup = await Api.getDatabaseBackup();
				const content = JSON.stringify(backup);
				const a = document.createElement("a");
				const file = new Blob([content], { type: "text/plain" });
				a.href = URL.createObjectURL(file);
				a.download = "backup.json";
				a.click();
			})()
			.catch(error => this.setState({ error }))
			.finally(() => {
				this.setState({ downloadingBackup: false });
			});
		});
	}
	public async saveToSpreadsheet () {
		this.setState({ spreadsheetLoading: true });
		const areYouSure = window.confirm([
			"Are you sure you want to export the database to the spreadsheet?",
			"If the spreadsheet contains unsaved changes, they will be lost.",
		].join("\n"));
		if (!areYouSure) {
			this.setState({ spreadsheetLoading: false });
			return;
		}
		await Api.saveToSpreadsheet();
		alert("Database saved to spreadsheet!");
		this.setState({ spreadsheetLoading: false });
	}
	public async loadFromSpreadsheet () {
		this.setState({ spreadsheetLoading: true });
		const { updates, summary } = await Api.loadUpdatesFromSpreadsheet();
		if (updates.length === 0) {
			alert("No updates to apply");
			this.setState({ spreadsheetLoading: false });
			return;
		}
		const areYouSure = window.confirm([
			"Are you sure you want to apply the following updates?",
			summary,
		].join("\n"));
		if (!areYouSure) {
			this.setState({ spreadsheetLoading: false });
			return;
		}
		await Api.applyUpdatesFromSpreadsheet(updates);
		alert("Updates applied!");
		this.setState({ spreadsheetLoading: false });
	}
	public render (): JSX.Element {
		if (this.state.isLoading)
			return <Loading />;
		return <div>
			<ErrorMessage err={ this.state.error } />
			<div className={ styles.container }>
				<div>
					<b>{ this.state.name }</b>'s profile
				</div>
				<div className={ styles.changes }>
					<div>
						<h4>Change name</h4>
						<div>
							<div>New name</div>
							<Input
								placeholder="New name"
								value={ this.state.newName }
								onChange={ newName => this.setState({ newName }) }
								onEnter={ () => this.save() }
							/>
						</div>
					</div>
					<div>
						<h4>Change password</h4>
						<div>
							<div>New password</div>
							<Input
								placeholder="New password"
								value={ this.state.password.new }
								onChange={ newPass => this.setState({ password: { ...this.state.password, new: newPass } }) }
								password
								onEnter={ () => this.save() }
							/>
						</div>
						<div>
							<div>Confirm password</div>
							<Input
								placeholder="Confirm password"
								value={ this.state.password.confirm }
								onChange={ confirm => this.setState({ password: { ...this.state.password, confirm } }) }
								password
								onEnter={ () => this.save() }
							/>
						</div>
					</div>
					<div>
						<h4>Save changes</h4>
						<Input
							placeholder="Current password"
							value={ this.state.password.current }
							onChange={ current => this.setState({ password: { ...this.state.password, current } }) }
							password
							onEnter={ () => this.save() }
						/>
						<Button
							name="Save changes"
							onClick={ () => this.save() }
						/>
					</div>
				</div>
				<Button
					type={ ButtonType.Danger }
					name="Log out"
					onClick={ () => this.logOut() }
				/>
				{
					this.state.isModerator ?
					<Button
						type={ ButtonType.Warning }
						name="Save to spreadsheet"
						onClick={ () => this.saveToSpreadsheet() }
						disabled={ this.state.spreadsheetLoading }
					/> :
					<></>
				}
				{
					this.state.isModerator ?
					<Button
						type={ ButtonType.Warning }
						name="Load from spreadsheet"
						onClick={ () => this.loadFromSpreadsheet() }
						disabled={ this.state.spreadsheetLoading }
					/> :
					<></>
				}
				{
					this.state.isAdmin ? 
					<Button
						type={ ButtonType.Success }
						name="Download database backup"
						onClick={ () => this.downloadDatabaseBackup() }
						disabled={ this.state.downloadingBackup }
					/> :
					<></>
				}
			</div>
		</div>;
	}
}
