import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

import styles from "./index.module.scss";

interface Props {
	icon: IconProp;
	onClick?: () => void;
	color?: string;
}

export default class IconButton extends Component<Props> {
	public render (): JSX.Element {
		return <div
			tabIndex={ 0 }
			className={ styles.container }
			onClick={() => {
				if (this.props.onClick === undefined)
					return;
				this.props.onClick();
			}}
			style={{
				color: this.props.color,
			}}
		>
			<FontAwesomeIcon icon={ this.props.icon } />
		</div>;
	}
}
