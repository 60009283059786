import Api from "api";
import React, { Component } from "react";

import styles from "./index.module.scss";

type Props = {};

interface State {
	moderators: Array<string>;
}

export class Footer extends Component<Props, State> {
	private readonly maker = "DapperMink";
	private readonly maintainer = "Mlpitch";
	private readonly specialThanks = ["Mlpitch", "Klazen"];
	public constructor (props: Props) {
		super(props);
		this.state = {
			moderators: [],
		};
	}
	public componentDidMount (): void {
		(async () => {
			const mods = await Api.getMods();
			this.setState({
				moderators: mods.map(mod => mod.name),
			});
		})()
		.catch(console.error);
	}
	public renderName (name: string): JSX.Element {
		return <b>{ name }</b>;
	}
	public renderNameList (names: Array<string>): JSX.Element {
		const temp = (name: string, index: number): JSX.Element => {
			let rName = this.renderName(name);
			if (index === 0)
				return rName;
			if (index === names.length - 1)
				return <>{ " and " }{ rName }</>;
			return <>{ ", " }{ rName }</>;
		}
		return <>
			{
				names.map((name, index) => <span key={ index }>{ temp(name, index) }</span>)
			}
		</>;
	}
	public render (): JSX.Element {
		return <footer className={ styles.container }>
			<div>
				Maintained with love by our awesome moderators:
				<br />
				{ this.renderNameList(this.state.moderators) }
				<br />
				Made by { this.renderName(this.maker) }
				<br />
				Special thanks to { this.renderNameList(this.specialThanks) }
			</div>
		</footer>;
	}
}
