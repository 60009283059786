import React, { Component } from "react";

import styles from "./index.module.scss";

export class Row extends Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return <div className={ styles.container }>
            { this.props.children }
        </div>;
    }
}