import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import Api, { Comment } from "api";
import { Role } from "../common";
import account from "pages/account";
import React, { Component } from "react";
import Button, { ButtonType } from "ui/button";
import IconButton from "ui/icon-button";
import Loading from "ui/loading";

import styles from "./index.module.scss";
import { getRoleColor } from "./utils";

interface Props {
	game: string;
}

enum Mode {
	Reading,
	Writing,
	Editing,
}

interface State {
	comments: Array<Comment>;
	isLoading: boolean;
	isModerator: boolean;
	mode: Mode;
	writingContent: string;
	editingId: number;
}

export default class Comments extends Component<Props, State> {
	public constructor (props: Props) {
		super(props);
		this.state = {
			comments: [],
			isLoading: true,
			isModerator: false,
			mode: Mode.Reading,
			writingContent: "",
			editingId: -1,
		};
	}
	public componentDidMount (): void {
		(async () => {
			const [comments, role] = await Promise.all([
				Api.getComments(this.props.game),
				Api.getRole(),
			]);
			this.setState({
				comments,
				isModerator: role === Role.Moderator || role === Role.Admin,
				isLoading: false,
			});
		})()
		.catch(console.error);
	}
	public async write (): Promise<void> {
		const role = await Api.getRole();
		if (role === Role.Everyone)
			return account.go();
		this.setState({
			mode: Mode.Writing,
		});
	}
	public async post (): Promise<void> {
		switch (this.state.mode) {
			case Mode.Writing:
				await Api.postComment(this.props.game, this.state.writingContent);
				break;
			case Mode.Editing:
				await Api.editComment(this.state.editingId, this.state.writingContent);
				break;
			default:
				throw new Error("Unexpected post comment");
		}
		window.location.reload();
	}
	public renderPost (): JSX.Element {
		if (this.state.mode === Mode.Reading)
			return <Button
				name="Write a comment"
				onClick={ () => {
					this.write()
					.catch(console.error);
				}}
			/>;
		return <>
			<textarea
				className={ styles.textarea }
				autoFocus
				value={ this.state.writingContent }
				onChange={ evt => this.setState({ writingContent: evt.target.value }) }
			/>
			<span>
				<Button
					name="Cancel"
					type={ ButtonType.Danger }
					onClick={ () => this.setState({
						writingContent: "",
						mode: Mode.Reading,
						editingId: -1,
					}) }
				/>
				<Button
					name={ this.state.mode === Mode.Editing ? "Edit" : "Post" }
					onClick={ () => {
						this.post()
						.catch(console.error);
					}}
				/>
			</span>
		</>;
	}
	public async delete (comment: Comment): Promise<void> {
		await Api.deleteComment(comment.id);
		window.location.reload();
	}
	public renderOptions (comment: Comment): JSX.Element {
		return <span className={ styles.options }>
			{ comment.own ?
				<IconButton
					icon={ faPen }
					onClick={ () => this.setState({
						mode: Mode.Editing,
						writingContent: comment.content,
						editingId: comment.id,
					}) }
				/> : <></>
			}
			{ comment.own || this.state.isModerator ?
				<IconButton
					icon={ faTrash }
					onClick={ () => {
						this.delete(comment)
						.catch(console.error);
					}}
				/> : <></>
			}
		</span>;
	}
	public render (): JSX.Element {
		if (this.state.isLoading)
			return <Loading />;
		return <div className={ styles.container }>
			<h3>Comments ({ this.state.comments.length })</h3>
			{ this.renderPost() }
			{
				this.state.comments
				.filter(comment => comment.id !== this.state.editingId)
				.map((comment, i) => <div key={ i }>
					<span
						className={ styles.author }
						style={{
							color: getRoleColor(comment.author.role),
						}}
					>{ comment.author.name }</span>
					<span className={ styles.date }>{ new Date(comment.date).toLocaleString() }</span>
					<span className={ styles.edited }>{ comment.edited ? "(edited)" : "" }</span>
					{ this.renderOptions(comment) }
					<p
						className={ styles.content }
						style={{
							whiteSpace: "pre-line",
						}}
					>{ comment.content }</p>
				</div>)
			}
		</div>;
	}
}
