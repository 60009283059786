import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Config {
	name: string;
	content: JSX.Element;
	icon?: IconProp;
	default?: boolean;
	menu?: boolean;
	title?: boolean;
}

export class Page {
	private readonly name: string;
	private readonly content: JSX.Element;
	private readonly default: boolean = false;
	private readonly menu: boolean;
	private readonly title: boolean;
	private readonly icon?: IconProp;
	public constructor (config: Config) {
		this.name = config.name;
		this.content = config.content;
		this.icon = config.icon;
		if (config.default ?? false) {
			if (Page.hasDefault)
				throw new Error("Only one page should be default");
			Page.hasDefault = true;
			this.default = true;
		}
		this.menu = config.menu ?? true;
		this.title = config.title ?? true;
		Page.list.push(this);
	}
	public getName (): string {
		return this.name;
	}
	public getURL (): string {
		return this.name
			.toLowerCase()
			.replace(/\s/g, "-");
	}
	public getIcon (): IconProp {
		if (this.icon === undefined)
			throw new Error("Unexisting icon");
		return this.icon;
	}
	public go (): void {
		window.location.assign(`/${ this.getURL() }`);
	}
	public isInMenu (): boolean {
		return this.menu;
	}
	public getContent (): JSX.Element {
		return this.content;
	}
	private isDefault (): boolean {
		return this.default;
	}
	public isSelected (): boolean {
		return Page.getSelected() === this;
	}
	public renderTitle (): JSX.Element {
		if (!this.title)
			return <></>;
		return <h3 style={{
			textAlign: "center",
			fontSize: "1.75rem",
		}}>{ this.getName() }</h3>;
	}
	private static getSelected (): Page {
		const pageNames = window
			.location
			.pathname
			.split("/")
			.filter(a => a.length > 0);
		if (pageNames.length > 0) {
			const pageName = pageNames[0];
			for (const page of Page.list) {
				if (page.getURL() === pageName)
					return page;
			}
		}
		for (const page of Page.list) {
			if (page.isDefault())
				return page;
		}
		throw new Error("No default page");
	}
	private static hasDefault: boolean = false;
	private static list: Array<Page> = [];
}
