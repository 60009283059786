import { Page } from "./page";

import home from "./home";
import search from "./search";
import submit from "./submit";
import comments from "./comments";
import discord from "./discord";
import extension from "./extension";
import policy from "./policy";
import account from "./account";
import game from "./game";

export const pages: Array<Page> = [
	home,
	search,
	submit,
	comments,
	discord,
	extension,
	policy,
	account,
	game,
];
