import React, { Component } from "react";

import styles from "./index.module.scss";

export type ErrorType = Error | string | undefined;

interface Props {
	err: ErrorType;
}

export class ErrorMessage extends Component<Props> {
	public getMessage (err: ErrorType): string {
		if (err === undefined)
			return "";
		if (typeof err === "string")
			return err;
		return err.message;
	}
	public render (): JSX.Element {
		const message = this.getMessage(this.props.err);
		if (message.length === 0)
			return <></>;
		return <div className={ styles.container }>
			<b>Error:</b> { message }
		</div>;
	}
}
