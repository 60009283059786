import { Footer } from "footer";
import { Row } from "ui/row";
import React, { Component } from "react";
import { pages } from "pages";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./index.module.scss";

export class App extends Component {
	public render (): JSX.Element {
		return <>
			<nav className={ styles.navbar }>
				<ul className="navbar-nav">
					<li className={ styles.logo }>
						<a href="/" className="nav-link">
							<span className="link-text">Fangame Archive</span>
							<FontAwesomeIcon icon={ faAngleDoubleRight }/>
						</a>
					</li>
					{
						pages
						.filter(page => page.isInMenu())
						.map((page, index) => <li
							className="nav-item"
							key={ index }
						>
							<a
								href={ `/${ page.getURL() }` }
								className="nav-link"
								custom-selected={ page.isSelected().toString() }
							>
								<FontAwesomeIcon icon={ page.getIcon() }/>
								<span className="link-text">{ page.getName() }</span>
							</a>
						</li>)
					}
				</ul>
			</nav>
			<div className={ styles.container }>
				<Row>
					<div className={ styles.content }>
						{
							pages
							.filter(page => page.isSelected())
							.map(page => <>
								{ page.renderTitle() }
								{ page.getContent() }
							</>)[0]
						}
					</div>
				</Row>
				<Footer />
			</div>
		</>;
	}
}
