import React, { Component } from "react";

import styles from "./index.module.scss";

export enum ButtonType {
	Normal,
	Danger,
	Success,
	Warning,
}

interface Props {
	name: string;
	href?: string;
	onClick?: () => void;
	type?: ButtonType;
	disabled?: boolean;
}

export default class Button extends Component<Props> {
	public constructor (props: Props) {
		super(props);
		if (this.props.href !== undefined && this.props.onClick !== undefined)
			throw new Error("You have to choose either href or onClick, not both");
	}
	public getType (): ButtonType {
		return this.props.type ?? ButtonType.Normal;
	}
	public getDisabled (): boolean {
		return this.props.disabled ?? false;
	}
	public getCustom(type: ButtonType): string {
		if (this.getType() === type)
			return "true";
		return "false";
	}
	public render (): JSX.Element {
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		return <a
			tabIndex={ 0 }
			className={ styles.container }
			href={ this.getDisabled() ? undefined : this.props.href }
			target="_blank"
			rel="noopener noreferrer"
			custom-danger={ this.getCustom(ButtonType.Danger) }
			custom-warning={ this.getCustom(ButtonType.Warning) }
			custom-success={ this.getCustom(ButtonType.Success) }
			custom-disabled={ this.getDisabled() ? "true" : "false" }
			onClick={() => {
				if (this.props.onClick === undefined)
					return;
				if (this.getDisabled())
					return;
				this.props.onClick();
			}}
		>
			{ this.props.name }
		</a>;
	}
}