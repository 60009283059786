import React, { Component } from "react";
import { Page } from "pages/page";
import Button from "ui/button";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";

class Extension extends Component {
	public readonly chromeURL = "https://chrome.google.com/webstore/detail/fangamearchive/lfepciaekhcpaimdodchemnfddniaceh";
	public readonly firefoxURL = "https://addons.mozilla.org/en-US/firefox/addon/fangame-archive/";
	public render (): JSX.Element {
		return <div>
			We have a chrome extension to add missing download links on delicious-fruit wherever possible.
			<br />
			You can install it from here:
			<br />
			<Button
				name="Google Chrome"
				href={ this.chromeURL }
			/>
			<br />
			<Button
				name="Mozilla Firefox"
				href={ this.firefoxURL }
			/>
		</div>;
	}
}

const page = new Page({
	name: "Extension",
	content: <Extension />,
	icon: faPuzzlePiece,
});

export default page;
