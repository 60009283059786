import React, { Component } from "react";

import styles from "./index.module.scss";

interface Props {
	value: string;
	onChange: (newValue: string) => void;
	onEnter?: () => void;
	placeholder?: string;
	password?: boolean;
}

export default class Input extends Component<Props> {
	public render (): JSX.Element {
		return <input
			className={ styles.container }
			value={ this.props.value }
			onChange={ evt => this.props.onChange(evt.target.value) }
			placeholder={ this.props.placeholder }
			onKeyPress={ e => {
				if (e.key === "Enter" && this.props.onEnter !== undefined)
					this.props.onEnter();
			} }
			type={ this.props.password ? "password" : undefined }
		/>;
	}
}
